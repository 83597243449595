import axios from 'axios'
export const GetMyTickets = (userID) => {

    return axios
    // .get(`http://localhost:9090/ticket/${userID}`, { withCredentials: true })
    .get(`https://gin-production-6435.up.railway.app/ticket/${userID}`, { withCredentials: true })

    .then((response) => {
        console.log(response.data)

        return response.data
    })
}