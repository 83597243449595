import React from 'react'
import  MyTicket  from './MyTicket'
import { useState, useEffect, CSSProperties } from 'react'
import { GetMyTickets } from '../../proxies/GetMyTickets';
import Table from 'react-bootstrap/Table';
import ClipLoader from "react-spinners/ClipLoader";


const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

interface Props {
    usernameEmit: string
    userID : Number
}

const MyTicketContainer = ({usernameEmit, userID} :Props) => {

    const [tickets, setTickets] = useState([])
    let [loading, setLoading] = useState(true);

    const fetchTickets = async (userID) => {
        console.log(userID)
        await GetMyTickets(userID)
        .then((data) => {
            setTickets(data.tickets)
            setLoading(false)

  

  
        })
        .catch((err) => {
            console.log(err)
        })  
    
    }
    useEffect(() => {
        fetchTickets(userID)
      }, [userID]);










  return (
    <div>
              {!loading ? (

        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Ticket nr</th>
                    <th>Movie name</th>
                    <th>Cinema room</th>
                    <th>Reserved Seat</th>
                    <th>Viewing date</th>
                    <th>Viewing start time</th>
                    <th>Viewing end time</th>
                    <th>Cancel ticket</th>

                </tr>
            </thead>

            {tickets.map((ticket: any) => 

                    <MyTicket   
                        usernameEmit={usernameEmit} 
                        userID={userID} 
                        ticket={ticket} 
                        ticketID={ticket.Id} 
                        key={ticket.Id}/> 
            )} 

        </Table>
        ) : (
        <div className="sweet-loading">

          <ClipLoader loading={loading} cssOverride={override} size={150} aria-label="Loading Spinner" />
          <h4 style={
            {
              textAlign: 'center'
            }
          }>
            Loading seats... 
            </h4>
            <p style={
              {
                textAlign: 'center'
              }
          }>(this can take a long time, my hosting sucks)</p>
       
        </div>
        )}
        

    </div>
  )
}

export default MyTicketContainer